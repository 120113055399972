.unAuthedPageWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

.authActionBox {
    width: calc(100% - 60px);
    max-width: 400px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(159, 179, 200, 0.06),
        0px 4px 6px rgba(159, 179, 200, 0.1);
    border-radius: 16px;
    padding: 24px;

    &__Header {
        color: #102a43;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .css-o1p5ca {
        border-radius: 5px;
        color: #e53e3e;
        opacity: 0;
        animation: errorFadeIn 0.5s ease 0s 1 forwards;
    }

    &__main {
        margin-top: 17px;
        margin-bottom: 30px !important;
    }

    &__Btn {
        width: 100% !important;
    }

    &__actionLink {
        display: inline-block;
        width: 100%;
        text-align: center;
        color: #006ee5;
        font-size: 14px;
        font-weight: 500;
        margin-top: 40px;
    }
}

.otherActionsGroup {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}

.actionLink {
    color: #007bff;
    font-size: 14px;
}

.Btn {
    &--Primary {
        transition: all 0.5s ease;
        color: #fff;
        background-color: #007bff !important;
        &:hover {
            background-color: #006ee5 !important;
        }
    }
}

@media (max-width: 500px) {
    .authActionBox {
        width: 100% !important;
        max-width: unset;
    }
}

@keyframes errorFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.emailGroup {
    width: 100%;
    margin: 20px 0;
    h2 {
        color: #829ab1;
        font-size: 13px;
        margin-bottom: 2px;
    }
    p {
        color: #334e68;
    }
}
