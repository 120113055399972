@import "@samedaycustom/assets/responsive-helper.scss";

.TSCPage {
    padding-top: 60px;
    .TSCconent {
        width: 100%;
        padding: 60px 10%;
        @include respondTo(medium) {
            padding: 67px 5%;
        }
        @include respondTo(small-medium) {
            padding: 67px 20px;
        }
    }
    h1 {
        text-align: center;
        font-size: 40px;
        text-align: center;
        margin-bottom: 25px;
        font-weight: 600;
        @include respondTo(medium) {
            font-size: 32px;
        }
    }
    h2 {
        font-size: 20px;
        margin-bottom: 20px;
        text-align: center;
        font-weight: 600;
    }
    p {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    h4 {
        margin-bottom: 20px;
        margin-top: 30px;
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
        span {
            margin-left: 25px;
        }
    }

    .tcsIndent {
        // padding-left: 25px;
        margin-left: 50px;
    }
    .tcsCapital {
        text-transform: uppercase;
        font-weight: 500;
    }
    ol {
        list-style: upper-latin;
    }
    li {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    .boldText {
        font-weight: 500;
    }
    .tscLink {
        color: #007bff;
        text-decoration: none;
        margin: 0 5px;
    }
    .listItemHeader,
    .tcsBold {
        font-weight: 600;
    }
}

.Landing__Navigation {
    width: 100%;
    padding: 17px 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    filter: drop-shadow(0px 4px 4px rgba(182, 200, 213, 0.2));
    &__menu {
        display: none;
    }
    @include respondTo(medium) {
        padding: 17px 5%;
        &__menu {
            display: flex;
            height: 32px;
            width: 32px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    @include respondTo(small-medium) {
        padding: 17px 20px;
    }

    .Landing_mobileMenu {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2000;
        width: 100%;
        background-color: #fff;
        height: calc(100vh);
        height: calc((var(--vh, 1vh) * 100));
        transform: translate(100%);
        opacity: 0;
        pointer-events: none;
        transition: all 0.5s ease;
        @include respondTo(medium) {
            display: block;
        }
        &.openMobileMenu {
            opacity: 1;
            pointer-events: all;
            transform: translate(0);
        }
        .mobileMenuFooter {
            position: absolute;
            bottom: 20px;
            left: 0;
            padding: 17px 5%;

            @include respondTo(small-medium) {
                padding: 17px 20px;
            }
            &__social {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 20px;
                .socialContainer {
                    height: 30px;
                    width: 30px;
                    background-color: #e5f1ff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    svg {
                        fill: #007bff;
                    }
                }
                & > :first-child {
                    margin-right: 20px;
                }
            }
        }
        .mobileMenuNav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            filter: drop-shadow(0px 4px 4px rgba(182, 200, 213, 0.2));
            padding: 17px 5%;

            @include respondTo(small-medium) {
                padding: 17px 20px;
            }
        }
        .mobileMenuLinks {
            padding: 17px 5%;
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            @include respondTo(small-medium) {
                padding: 17px 20px;
                padding-top: 50px;
            }
            & > a > span {
                margin-bottom: 35px;
                font-size: 16px;
                font-weight: 500;
                display: inline-block;
                cursor: pointer;
                transition: all 0.5s ease;
                &:hover {
                    color: #007bff;
                }
            }
            .actionGroupBtn {
                box-shadow: 0px 4px 16px rgba(0, 123, 254, 0.1);
                height: 52px !important;
                font-size: 16px !important;
                padding-left: 32px !important;
                padding-right: 32px !important;
            }
        }
    }

    &__grp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include respondTo(medium) {
            display: none;
        }

        .linksGroup {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & > :last-child {
                span {
                    margin-right: 20px;
                }
            }
            span {
                font-size: 16px;
                font-weight: 500;
                margin-right: 40px;
                display: inline-block;
                cursor: pointer;
                transition: all 0.5s ease;
                &:hover {
                    color: #007bff;
                }
                // &:last-child {
                //     margin-right: 0px;
                // }
            }
        }
        .actionGroup {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 100px;
            span.lgin {
                cursor: pointer;
                color: #007bff;
                font-size: 16px;
                font-weight: 500;
            }
            .actionGroupBtn {
                margin-left: 23px !important;
                box-shadow: 0px 4px 16px rgba(0, 123, 254, 0.1);
                height: 52px !important;
                font-size: 16px !important;
                padding-left: 32px !important;
                padding-right: 32px !important;
            }
        }
    }
}

.Landing__footer {
    width: 100%;
    padding: 67px 5%;
    @include respondTo(small-medium) {
        padding: 17px 20px;
    }
    .topFooter {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #bcccdc;
        @include respondTo(medium) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
        .footerLinks {
            @include respondTo(medium) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                span {
                    margin-right: 0;
                    margin-bottom: 20px;
                }
                & > :last-child {
                    span {
                        margin-bottom: 40px;
                    }
                }
            }

            & > :last-child {
                span {
                    margin-right: 20px;
                }
            }
            span {
                font-size: 16px;
                font-weight: 500;
                margin-right: 40px;
                display: inline-block;
                cursor: pointer;
                transition: all 0.5s ease;
                &:hover {
                    color: #007bff;
                }
                // &:last-child {
                //     margin-right: 0px;
                // }
            }
        }
        .socialLinks {
            display: flex;
            .socialContainer {
                height: 30px;
                width: 30px;
                background-color: #e5f1ff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                svg {
                    fill: #007bff;
                }
                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }
    .bottomFooter {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        @include respondTo(medium) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            p {
                margin-top: 20px;
                text-align: center;
            }
        }
    }
}
