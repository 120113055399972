.authActionBox{
    width: calc(100% - 60px);
    max-width: 400px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(159, 179, 200, 0.06), 0px 4px 6px rgba(159, 179, 200, 0.1);
    border-radius: 16px;
    padding: 24px;

    &__Header{
        color: #102A43;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 30px;
    }

    &__main{
        margin-bottom: 30px;
    }
    &__Btn{
        width: 100%;
    }
    &__actionLink{
        display: inline-block;
        width: 100%;
        text-align: center;
        color: #006EE5;
        font-size: 14px;
        font-weight: 500;
        margin-top: 40px;
    }
}

.otherActionsGroup{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.actionLink{
    color: #007bff;
    font-size: 14px;
}

.Btn {
	&--Primary {
		transition: all 0.5s ease;
		color: #fff;
		background-color: #007bff !important;
		&:hover {
			background-color: #006ee5 !important;
		}
	}
}

@media (max-width: 500px){
    .authActionBox{
        width: 100%;
        max-width: unset;
    }
}